@font-face {
  font-family: 'Helvetica';
  src: url('./components/fonts/Helvetica.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./components/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Helvetica', sans-serif;
  line-height: 1.6;
}

body[data-theme='dark'] {
  background: #121212;
  color: #ffffff;
}

body[data-theme='light'] {
  background: #f9f9f9;
  color: #000000;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.content {
  margin-top: 60px;
  flex: 1;
}

footer {
  height: 60px;
  background: var(--footer-bg);
  color: var(--footer-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

body[data-theme='light'] {
  --footer-bg: #ffffff;
  --footer-text-color: #333;
}

body[data-theme='dark'] {
  --footer-bg: #1a1a1a;
  --footer-text-color: #ddd;
}

h1, h2, h3, h4 {
  font-family: 'Helvetica', sans-serif;
}

section {
  padding: 3rem 1.5rem;
  transition: all 0.3s ease-in-out;
}

section:nth-of-type(even) {
  background: rgba(255, 255, 255, 0.05);
}

section:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.05);
}

section:not(:last-of-type) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

section > *:not(:last-child) {
  margin-bottom: 2rem;
}

header + section {
  margin-top: 80px;
}
