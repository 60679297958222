.projects-card {
  padding: 4rem;
}

.projects-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 3rem !important;
}

.project-category {
  margin-bottom: 2rem;
}

.category-title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 24px !important;
}

.project-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.project-item:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.project-icon-container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.project-icon {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.project-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
}

.project-modal {
  background: var(--modal-bg);
  padding: 2.5rem;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1500;
}

@media (max-width: 768px) {
  .project-modal {
    max-width: 70%;
  }
}


.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.modal-icon img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.modal-description {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.modal-link {
  color: var(--link-color);
  font-weight: bold;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
}


body[data-theme='light'] {
  --text-color: #000;
  --modal-bg: #fff;
  --link-color: #0078d7;
}

body[data-theme='dark'] {
  --text-color: #fff;
  --modal-bg: #1e1e1e;
  --link-color: #61dafb;
}
