footer {
  width: calc(100%); 
  height: 60px;
  background: var(--footer-bg);
  color: var(--footer-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}


.footer-text {
  font-size: 0.9rem;
  font-weight: 500;
}

body[data-theme='light'] {
  --footer-bg: #ffffff;
  --footer-text-color: #333;
}

body[data-theme='dark'] {
  --footer-bg: #1a1a1a;
  --footer-text-color: #ddd;
}

@media (max-width: 768px) {
  .footer {
    height: 50px;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}
