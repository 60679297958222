.stats-container {
    padding: 4rem;
    border-radius: 15px;
    text-align: center;
  }
  
  .stats-header {
    color: var(--title-color);
    font-weight: bold;
    margin-bottom: 3rem !important;
  }
  
  .stats-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .stat-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.3);
  }
  
  .stat-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .stat-card:hover .stat-image {
    transform: scale(1.05);
  }
  
  body[data-theme='light'] {
    --title-color: #333;
  }
  
  body[data-theme='dark'] {
    --title-color: #ddd;
  }
  
  @media (max-width: 768px) {
    .stats-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .stat-card {
      padding: 0.8rem;
    }
  }
  